import React from "react";
import {Grid} from "@mui/material";
import SplashPanel from "./SplashPanel";
import MembershipPanel from "./MembershipPanel";
import RoadmapPanel from "./RoadmapPanel";
import FAQPanel from "./FAQPanel";
import ContactPanel from "./ContactPanel";
import {LandingPage} from "./landing/LandingPage";
import {IntroPanel} from "./IntroPanel";
import GameplayPanel from "./GameplayPanel";
import StealthPanel from "./landing/StealthPanel";
import DaojoPanel from "./DaojoPanel";
import SplashExPanel from "./SplashExPanel";

const MainPage = () => {
    return (
        <>
            <SplashExPanel/>
        </>
    )
}

export default MainPage;

/*
 <IntroPanel/>
            <GameplayPanel/>
            <DaojoPanel/>
            <MembershipPanel/>
            <StealthPanel/>
            <Grid container spacing={0}>
                <RoadmapPanel/>
                <FAQPanel/>
                <ContactPanel/>
            </Grid>
 */