import {Grid, Typography} from "@mui/material";
import React, {PropsWithChildren} from "react";
import './RoadmapPanel.css';
import Box from "@mui/material/Box";
import {WHITELIST_LINK} from "./components/constants";

interface ItemProps extends PropsWithChildren<any> {
    title: string;
}

const Item = ({title, children}: ItemProps) => {
    return <Box maxWidth={{md: "60%"}} marginBottom={1}>
        <Typography variant={"h4"} fontWeight={700}>
            {title}
        </Typography>
        <Box className={"items"} paddingTop={2} paddingBottom={4}>
            {children}
        </Box>
    </Box>
}

const SplashExPanel = () => {
    return <Box className={"roadmap"} height={"100"} width={"100vw"}>
        <Typography variant={"h2"} paddingTop={4} gutterBottom={true} style={{
            fontWeight: 700,

        }}>
                    <span style={{
                        color: 'hsl(357,95%,30%)',
                        fontWeight: 200,
                        fontSize: '20px',
                    }}>
                        It's a Secret Fight Club <span style={{ opacity: 0.5 }}>🤫</span>
                    </span>
        </Typography>
    </Box>
}

export default SplashExPanel;

/*
 <Item title="April 2022">
            <Typography paragraph={true}>
                We will open the Blood Hammer Daojo Membership NFTs for the <a
                href={WHITELIST_LINK}>blacklist</a> presale and later, the public mint.
                These NFTs will allow for community access, governance, a wide variety of perks, including access to the
                SFC real money fantasy circuit. They will be the key to unlocking membership to the Blood Hammer Daojo.
            </Typography>
            <Typography paragraph={true}>
                We will give away the key to a Ferrari to one lucky winner along with an SFC
                memorial Pizza and a mystery prize. <a
                href={WHITELIST_LINK}>Blacklist</a> Members will automatically receive 10
                bonus entries.
            </Typography>
        </Item>
        <Item title="May 2022">
            <Typography paragraph={true}>
                We will gather our forces and build key alliances. You may even catch us not talking about Secret Fight Club on Twitter Spaces.
            </Typography>
            <Typography paragraph={true}>
                Several lucky active Discord members and Twitter followers will receive free pizza & merch because why not.
            </Typography>
        </Item>
        <Item title="June 2022">
            <Typography paragraph={true}>
                Blood Hammer Daojo Membership NFTs presale and public mint. Probably.
            </Typography>
            <Typography paragraph={true}>
                We will extend invite-only access to the live version of the SFC real money Fantasy
                Sports circuit for a select number of early adopters and active Discord supporters as well as all
                Daojo Members.
            </Typography>
            <Typography paragraph={true} fontWeight={"bolder"}>
                Please note that anyone participating in live SFC Fantasy Sports contests for cash prizes must
                be over 18.
            </Typography>
            <Typography paragraph={true}>
                Tentatively drop Membership NFTs for two more Daojo will be made available along with a release
                schedule for the Membership NFTs of the remaining seven Daojos (10 total).
            </Typography>
            <Typography paragraph={true}>
                Separately, we will throw a celebratory pizza party for one lucky active Discord member.
            </Typography>
        </Item>
        <Item title="Mid 2022">
            <Typography paragraph={true}>
                Access to the SFC fantasy contests will be extended to the wider community, including all Discord
                members, as part of a public beta. To commemorate this milestone, all Daojo Membership holders and
                early active Discord supporters will receive exclusive merch for free.
            </Typography>
            <Typography paragraph={true}>
                As a fast follow, SFC Fantasy Sports contests will be expanded to include a new mode and prize
                structure. Members will be able to complete for a bigger individual share, of larger prize pool,
                more often.
            </Typography>
            <Typography paragraph={true}>
                The Daojos will organize with our support and SFC will release the first set of NFT items, starting with
                cosmetics. Members will have to opportunity to begin customizing, designing, and modifying their Daojo's
                AI fighter.
            </Typography>
            <Typography paragraph={true}>
                We will begin accepting submissions from the SFC & Discord community for future NFT items and cosmetics
                TBD.
            </Typography>
            <Typography paragraph={true}>
                Evil Network News (ENN) — your untrusted source for the latest SFC news from the Pentagramagon — will go
                live on twitter at <a href={"https://twitter.com/evilnetworknews"}>@EvilNetworkNews</a> and
                in <a href={"https://discord.gg/vFcSdP3Bvr"}>our Discord</a>.
            </Typography>
        </Item>
        <Item title="Late 2022 - Early 2023">
            <Typography paragraph={true}>
                In late 2022 and early 2023, Daojos will gain additional features and functionality with expanded
                training, modifiers, and governance of their AI fighter.
            </Typography>
            <Typography paragraph={true}>
                We plan to introduce additional Daojo activities as well access to innovative opportunities for Daojo
                Members such as opening sponsorships for their AI fighter.
            </Typography>
            <Typography paragraph={true}>
                We will release a limited number of non-voting Superfan Memberships for each Daojo — enabling fans to
                support their favorite AI fighter directly through NFT contributions in addition to number of other
                exclusive perks and benefits. Superfan Memberships are an exclusive annual membership experience built
                for a Daojo’s superfans.
            </Typography>
            <Typography paragraph={true}>
                SFC seasonal championships with tournament prize pools will be announced.
            </Typography>
            <Typography paragraph={true}>
                Dramatically increased opportunities for long-term engagement through narrative storytelling.
            </Typography>
        </Item>
        <Item title="2023 and beyond...">
            <Typography paragraph={true}>
                In 2023 and beyond, the SFC community can expand to include new AI fighters, games, and interactive ways
                to engage with each other in and out of the SFC world — increasing value for Daojos through enhanced
                engagement and monetization opportunities.
            </Typography>
            <Typography paragraph={true}>
                Partner with SpaceX to put a working laser on the moon.
            </Typography>
            <Typography paragraph={true}>
                Distract all earthbound cats from space.
            </Typography>
            <Typography paragraph={true} gutterBottom={true}>
                That’s all we can reveal for now.
            </Typography>
        </Item>
 */
