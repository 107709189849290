import React from 'react';
import MainPage from "./MainPage";
import {Navigate, Route, Routes} from "react-router-dom";
import LegalPage from "./LegalPage";
import Box from "@mui/material/Box";
import ManualPage from "./manual/ManualPage";
import './App.css';

export const RickRollContext = React.createContext({
    error: null,
    addError: () => {
    },
    removeError: () => {
    }
});

function App() {
    return (
        <Box className="App">
            <header className="App-header">
                <Routes>
                    <Route path="/" element={<MainPage/>}/>
                    <Route path="*" element={<Navigate to="/" replace/>}/>
                </Routes>
            </header>
        </Box>
    );
}

export default App;

//           <Route path="src/secret/*" element={<ManualPage/>}/>
//                     <Route path="legal" element={<LegalPage/>}/>
